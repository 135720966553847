import * as React from "react";
import { Heading } from "../../components";
import { SocialPortfolio, BackButton } from "../../components";

const SocialPage = () => {
  return (
    <div className="container">
      <Heading title="Social Media" cursive="Portfolio" />
      <BackButton />
      <SocialPortfolio />
    </div>
  )
}

export default SocialPage

export const Head = () => <title>TMOY || Social Media</title>